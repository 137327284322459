
@font-face {
  font-family: "Motiva Sans Light";
  src: url("/fonts/Motiva-Sans-Light.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Motiva Sans Bold";
  src: url("/fonts/Motiva-Sans-Bold.ttf") format("truetype");
  font-style: normal;
}

body, html, a {
  font-family: 'Motiva Sans Light', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: #fff;
  overflow-x: hidden;
  font-size: 1rem;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Motiva Sans Bold', serif;
  line-height: 1.18;
}

h1 { font-size: 56px; }
h2 { font-size: 32px; }
h3 { font-size: 20px; }

@media only screen and (max-width: 890px) {
  h1 { font-size: 47px; }
  h2 { font-size: 20px; }
  h3 { font-size: 18px; }
}

@media only screen and (max-width: 414px) {
  h1 { font-size: 32px; }
  h2 { font-size: 20px; }
  h3 { font-size: 18px; }
}

p {
  color: #000000;
  font-size: 21px;        
  line-height: 1.41;
}

img {
  vertical-align: middle;
  border-style: none;
}

a:focus, a:hover {
  text-decoration: underline;
}
