
$primary: #14213d;
$secondary: rgb(255,130,92);
$line-width: 2px; // horizontal lines around headers and sections

/* .desktop and .mobile classes for visibility */

@media only screen and (max-width: 890px) {
  .desktop {
    display: none;
  }
}

@media only screen and (min-width: 890px) {
  .mobile {
    display: none;
  }
}

/* header */

.header-container {
  border-bottom: $line-width solid $primary;
}

header {
  padding: 1rem 0.5rem;

  .ant-row-space-between {
    align-items: center;
    text-align: center;
  }
}

.ant-drawer-body {
  display: flex;
  flex-direction: column;
}

.ant-drawer-content-wrapper {
  width: 300px !important;
}

.drawer-header {
  margin: 0 1rem 2.5rem 1rem;
  font-weight: 500;
  font-size: 25px;
  color: #404041;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

header a, .ant-drawer-body a {
  font-size: 1.2rem;
  color: #18216d;
  transition: all 0.2s ease-in;
  margin: 0.5rem 2rem;
  text-align: center;

  &:hover, &:focus {
    text-decoration: $secondary wavy underline;
    color: $secondary;
  }
}

/* container: the header, footer and each section at the highest level;
   adds margins based on screen size */
.container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 60px;

  @media only screen and (max-width: 1024px) {
    max-width: calc(100% - 68px);
    padding: 0 30px;
  }

  @media only screen and (max-width: 768px) {
    max-width: calc(100% - 38px);
    padding: 0 18px;
  }

  @media only screen and (max-width: 414px) {
    max-width: 100%;
    padding: 0 18px;
  }
}

/* content sections */

.content-section {
  position: relative;
  padding: 8rem 0 5rem;
  @media only screen and (max-width: 1024px) {
    padding: 4rem 0 4rem;
  }
  &.compact {
    padding: 0;
    padding-top: 4rem;
  }
}

@media only screen and (max-width: 576px) {
  .content-section.flipped {
    flex-wrap: wrap-reverse;
  }
  .content-section > div,
  footer .ant-col {
    margin-bottom: 3rem;
  }
  footer .ant-col:last-of-type {
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 577px) {
  .content-section {
    margin-bottom: 3rem;
  }
}

.partner .profile {
  h2, div, .ant-avatar, em {
    margin: 0 auto;
    display: block;
    width: 100%;
    text-align: center;
  }
  .ant-avatar {
    margin: 0 auto 2rem auto;
  }
  h2 {
    margin-bottom: 0.25rem;
  }
  em {
    color: #333;
  }
  .socials a {
    margin: 0 0.5rem;
  }
}

/* carousel: projects / portfolio */

.ant-carousel .project {
  background-color: white;
  margin-top: 16px;
  height: 80vh;
  font-size: 1rem;
  position: relative;
  &, * {
    color: white;
  }
  a {
    color: #fca311;
  }
  .container {
    height: 100%;
    // bottom dots bar ends 42px above the bottom
    // bottom dots bar is 26px above the bottom and 16px tall
    margin-bottom: 26px * 2 + 12px;
    margin-top: 26px;
  }
  .project-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: contain;
  }
  .project-info {
    position: absolute;
    width: 100%;
    background-color: rgba($primary, 0.8);
    display: block;
    bottom: 0;
  }
}

.logo-banner {
  background-color: rgb(241,242,243);
  font-size: 1rem;
  position: relative;
  align-items: center;
  padding-bottom: 3rem;
}

.logo {
  height: 10vw;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-around;
  display: flex;
  &, * {
    color: white;
  }
  a {
    color: #fca311;
  }
}

.logo-background {
  height: 10vw;
  filter: grayscale(1);
}


@media only screen and (max-width: 576px) {
  .project-info p {
    font-size: 1rem;
  }
}

.slick-dots {
  li, li > button {
    height: 1rem !important;
    border-radius: 1rem !important;
  }
  li {
    margin: 0 6px !important;
  }
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
  font-size: inherit;
  color: currentColor;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover {
  left: 10px;
  z-index: 2;
  color: white;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:hover {
  right: 10px;
  z-index: 2;
  color: white;
}


/* footer */

footer, .section-header {
  background: rgb(241,242,243);
  padding: 3rem 0;
  border-top: $line-width solid $primary;
}

.section-header h1 {
  width: 100%;
  text-align: center;
  margin: 1rem;
}

.section-subheader h2 {
 width: 100%;
 text-align: center;
 margin: 1rem
}

.section-subheader {
  background: rgb(241,242,243);
  padding: 1rem 0;
  border-top: $line-width solid $primary;
}

#center-button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5rem;
  max-width: 12rem;
}

.partner-container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 60px;
  padding-bottom: 2rem;
}

.small-partner {
  padding-top: 2rem;
}